// extracted by mini-css-extract-plugin
export var root = "ProductCard-module--root--58341";
export var detailsContainer = "ProductCard-module--detailsContainer--19c98";
export var imageContainer = "ProductCard-module--imageContainer--1bfca";
export var productName = "ProductCard-module--productName--2ae15";
export var prices = "ProductCard-module--prices--4558f";
export var salePrice = "ProductCard-module--salePrice--44f4d";
export var originalPrice = "ProductCard-module--originalPrice--80651";
export var meta = "ProductCard-module--meta--a3ef0";
export var bagContainer = "ProductCard-module--bagContainer--23adc";
export var heartContainer = "ProductCard-module--heartContainer--064f3";
export var heartFillContainer = "ProductCard-module--heartFillContainer--2eac0";
export var show = "ProductCard-module--show--ed8e1";
export var hide = "ProductCard-module--hide--e0774";